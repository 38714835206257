import type { OASDocument } from 'oas/types';

import React from 'react';
import { useFormContext, FormProvider, useForm } from 'react-hook-form';

import type { UploadImageResponse } from '@ui/ImageUploader';

export interface SignupFormValues {
  appearance: {
    colors: {
      main: string;
    };
    logo: UploadImageResponse;
  };
  /**
   * Processed OAS spec returned from /api-validation
   * used to preview uploaded OAS in the MockHub for valid specs
   * */
  docsPreview?: OASDocument & { isExample?: boolean };
  hostedURL?: string;
  modules: {
    changelog: boolean;
    discuss: boolean;
    docs: boolean;
    landing: boolean;
    reference: boolean;
    tutorials: boolean;
  };
  name: string;
  oasFile?: File;
  subdomain: string;
  // Successful uploaded OAS ID
  successfulUploadId?: string;
}

interface Props {
  children: React.ReactNode;
}

/**
 * Sets up the React Hook Form instance that manages our project configuration
 * form. Renders it with a `FormProvider` that allows all child components to
 * access the form context and all of its properties.
 *
 * Child components should call `useSignupFormContext()` hook to access the
 * form context that is properly typed.
 *
 * @link https://react-hook-form.com/docs/formprovider
 */
export default function SignupFormProvider({ children }: Props) {
  const formValue = useForm<SignupFormValues>({
    defaultValues: {
      appearance: {
        colors: {
          main: '',
        },
        logo: [],
      },
      modules: {
        changelog: false,
        discuss: true,
        docs: true,
        landing: false,
        // We'll enable API Reference by default
        reference: true,
        tutorials: false,
      },
      name: '',
      subdomain: '',
    },
  });

  return <FormProvider {...formValue}>{children}</FormProvider>;
}

/**
 * Convenience hook to get our React Hook Form Context with types that match our
 * project mapper form fields.
 * @link https://react-hook-form.com/docs/useformcontext
 */
export const useSignupFormContext = () => useFormContext<SignupFormValues>();
